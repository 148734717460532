
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: rgba(199, 185, 185, 0.6);
}

*::placeholder {
  color: rgba(196, 186, 186, 0.2);
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

canvas {
  position: absolute;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* @media (orientation: landscape) {
  button {
    flex-direction: row;
  }
}

@media (orientation: portrait) {
  button {
    flex-direction: column;
  }
} */