.container{
    width: 100vw;
    height: 100vh;
    background-color: #f5f9f4;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:15px
}
.tittle{
    font-family: 'FONTSPRING DEMO - Neometric Extra Bold',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    letter-spacing: 0.05em;
    color: #0F0F0F;
    margin-left: 35px;
}
.upload_label{
    font-family: 'Barlow', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.05em;
    color: #000000;
    margin-top: 40px;
}
.upload_icon{
   margin-left: 10px;
}
.browse_button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 194px;
    height: 40px;
    background: #174F00;
    border-radius: 5px;
    font-family: 'Barlow', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    margin-top: 10px;

}
.urllabel_label{

    font-family: 'Barlow', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.05em;
    color: #000000;
    margin-top: 40px;

}
.url_input{
    height: 40px;
    background: rgba(23, 79, 0, 0.02);
    border: 1px solid #174F00;
    border-radius: 5px;
    width: 100%;
}
.upload_button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 194px;
    height: 40px;
    background: #174F00;
    border-radius: 5px;
    font-family: 'Barlow', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    margin-top: 10px;

}
.icon_container{
   display:flex;
   align-items: flex-start;
   width: 100%;
   justify-content: center;
   flex-direction: column;
}
.back_button{
    border: 1px solid #174F00;
    border-radius: 5px;
    background-color: #f5f9f4;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Barlow', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 0.05em;
    color: #000000;
    width: 80px;
    height: 32px;

}
.preview_container{
    display: flex;
    align-items: center;
    flex-direction: column;
}