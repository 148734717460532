* {
  padding: 0;
  margin: 0;
  box-sizing:border-box;
}
html{
height:100%;
}

.container{
position: relative;
}

body{
  justify-content: center;
  margin:0;
  background-color: rgb(82, 79, 79);
}
#root{
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

ul{
list-style: none;
}

a{
text-decoration: none;
}

a,
button,
select {
  outline: none;
}

img {
  max-width: 100%;
}
