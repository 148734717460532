.container{
    width: 100vw;
    height: 100vh;
    background-color: #f5f9f4;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:15px
}
.tittle{
    font-family: 'FONTSPRING DEMO - Neometric Extra Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    letter-spacing: 0.05em;
    color: #0F0F0F;
    margin-left: 35px;
}
.upload_label{
    font-family: 'Barlow', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.05em;
    color: #000000;
    margin-top: 40px;
}
.upload_icon{
   margin-left: 10px;
}
.browse_button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 194px;
    height: 40px;
    background: #174F00;
    border-radius: 5px;
    font-family: 'Barlow', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    margin-top: 10px;

}
.urllabel_label{

    font-family: 'Barlow', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.05em;
    color: #000000;
    margin-top: 40px;

}
.url_input{
    height: 40px;
    background: rgba(23, 79, 0, 0.02);
    border: 1px solid #174F00;
    border-radius: 5px;
    width: 100%;
}
.upload_button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 194px;
    height: 40px;
    background: #174F00;
    border-radius: 5px;
    font-family: 'Barlow', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    margin-top: 10px;

}
.icon_container{
   display:flex;
   align-items: flex-start;
   width: 100%;
   justify-content: center;
   flex-direction: column;
}
.back_button{
    border: 1px solid #174F00;
    border-radius: 5px;
    background-color: #f5f9f4;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Barlow', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 0.05em;
    color: #000000;
    width: 80px;
    height: 32px;

}
.preview_container{
    width: 100%;
    background-color: rgb(72, 72, 69);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-image: url("../../assets/preview_background.png");
    padding-bottom: 56.25%;

}
.preview{
    width: 100%;
    margin-top: 20px;
   
}

.icons{
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content:space-between;
    padding-right: 6px;
   

}
.icon{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.icon p{
    font-family: 'Barlow', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 0.05em;
    color: #0F0F0F;
    margin-top: 15px;

}
.preview_cont{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.control_icon{
    display: flex;
    justify-content:space-between;
    margin-top: 20px;
}

.slider_container{
    width: 100%;
    background-color: rgb(72, 72, 69);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
   .preview_list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: '10px';
    border:1px solid black;
    width: 100%;
    padding-left:  5px;
    margin-top: 3px;
    font-family: 'Barlow', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    
  };
  
  .remove_button{
    /* margin-left: '10px'; */
    /* padding: '1px 3px';
    background-color: 'red';
    color: 'black';
    border: 'none';
    border-radius: '4px';
    cursor: 'pointer' */
  };
  .preview_list p{
    color: #000000;
  }
  .list_title{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    background: 'red';

  }