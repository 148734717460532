.container {
    display: flex;
    justify-content: center;
  }
  
  .videoWrapper {
    background-color: #cfad58;
    width: 100%;
  
  }
  
  .videoContainer {
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: normal;
    max-width: auto;
    color: white;
    margin:  auto;
  }
  
  .player{
    margin-top:10px;
  }
  
  button {
    display: flex;
    margin: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    width: 130px;
    height: 50px;
    background: #b6860d;
    border: none;
    border-radius: 4px;
  }
  button:hover{
    background-color:#cfad58 ;
  }
  
  .button1 {
    border-radius: 30px 5px 5px 30px;
  }
  
  .button2 {
    border-radius: 5px 30px 30px 5px;
  }
  
  button span {
    color: rgb(46, 44, 44);
    font-size: 14px;
    margin: 3px;
  }
  @media (orientation: landscape) {
    button {
      flex-direction: row;
    }
  }
  
  @media (orientation: portrait) {
    button {
      flex-direction: column;
    }
  }
  
  
  #flexSwitchCheckDefault{
    background-color: darkgrey;
  }
  
  table{
    background-color: #514f4e;
    margin: 0 auto;
  }
  
  p {
    text-align: right;
  }