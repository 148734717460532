.bckg{
  background-color: rgb(131, 216, 169);
}

.custm_logo {
  align-self: center;
  padding: 10px ;
  text-decoration: none;
}

.custm_logo a img {
  width: 25px;
  margin-top:3px;
}

.custm_logo a span {
  font-size: 15px;
  font-weight: 400;
  font-family: "Arial";
  color:rgb(151, 139, 139);
  margin-left: 10px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}


.custm_logo a:hover {
  text-decoration: none;
}
